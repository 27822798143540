import React, { useEffect, useRef } from "react"
import * as styles from "./styles.module.scss"
import { Button, Container } from "react-bootstrap"
import LeftCardProfile from "../../../components/layout/LeftCardProfile"
import axios from "axios"
import user1 from "../../../assets/images/user.png"
import locationIcon from "../../../assets/images/location.png"
import ButtonProfile from "../../shared/ButtonProfile"

const ViewProfile = () => {
	const [user, setUser] = React.useState([])
	const [firstName, setFirstName] = React.useState("")
	const [lastName, setLastName] = React.useState("")
	const [license, setLicense] = React.useState("")
	const [phone, setPhone] = React.useState("")
	const [locationId, setLocationId] = React.useState("")
	const [emailAddress, setEmailAddress] = React.useState("")
	const [userID, setUserId] = React.useState("")
	const [userToken, setUserToken] = React.useState("")
	const firstUpdate = useRef(true);
	const [location, setLocation] = React.useState([])
	const [userFile, setUserFile] = React.useState(null)

	useEffect(() => {
		const id = localStorage.getItem("userId")
		// console.log(id)
		axios.get(`https://api.galeahealth.com/profile/user-image?user_id=${id}`, {}).then(res => {
			setUserFile(res.data)
			console.log('Get user file ',res.data)
			// toast.success('Get user file successfully')
		}).catch(err => {
			console.log(err)
		})
	},[])

	useEffect(()=>{
	console.log(userToken)
	console.log(userID)
	},[userToken,userID])

	//Set Data on LocalStorage
	useEffect(() => {
		localStorage.setItem("userData", JSON.stringify(user))
	}, [user]);

	//Get Data From Local Storage
	useEffect(() => {
		const userid = localStorage.getItem("userId")
		if (userid) {
			setUserId(userid)
		}
		const usertoken = localStorage.getItem("token")
		if (usertoken) {
			setUserToken(usertoken)
		}
	},[])

console.log(userID)
console.log(userToken)


	//Patch UseEffect
	useEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		const update = {
			first_name:firstName,
			last_name:lastName,
			email:emailAddress,
			license:license,
			phone:phone,
			location_id:locationId
		}

		axios.put("https://api.galeahealth.com/app/user", update, {
			headers:{
				Authorization: userToken,
				// contentType: 'multipart/form-data',
				"Content-Type": "application/json",
			}
		})
			.then((res) => {
				if (res.status === 200) {
					console.log(res.data)
				}
			})
			.catch((err) => {
				console.log(err)
			})
	}, [firstName, lastName, phone, emailAddress, license, locationId])

	useEffect(() => {
		const id = localStorage.getItem("userId")
		axios
			.get(`https://api.galeahealth.com/app/user/${id}`, {
				headers: {
					Authorization: `${userToken}`,
				},
			})
			.then((res) => {
				console.log(res.data)
				setUser(res.data)
				// const userData = res.data
				// localStorage.setItem("userData", JSON.stringify(userData))
			})
			.catch((err) => {
				console.log(err)
			})

		axios
			.get("https://api.galeahealth.com/app/location", {
				headers: {
					Authorization: `Bearer ${userToken}`,
				},
			})
			.then((res) => {
				// console.log(res.data);
				setLocation(res.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [userID])

	const handleLocation = (id) => {
		setLocationId(id)
	}

	return (
		<>
			<div className={styles.viewProfile}>
				<div>
					<Container className="">
						<div className="row pt-5">
							<div className="col-md-12 col-lg-4 pb-3">
								<div className={styles.leftCard}>
									<div className="pt-3" style={{ textAlign: "center" }}>
										<img src={user1} className={styles.leftCardImg} />
										<p className={styles.leftCardH1}>Imran</p>
										<span><img src={locationIcon}/> Alabama</span>
										<div style={{borderTop:'1px solid #dee2e6'}} className="mt-2 mb-2"/>
										<h5>t23gq</h5>
										<div className="text-center">
											<Button className={styles.leftCardBtn}>
												<svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall  css-f5io2" focusable="false"
														 style={{width: '1.5rem', height: '1.5rem', }} viewBox="0 0 24 24" aria-hidden="true">
												aria-hidden="true"  viewBox="0 0 24 24" data-testid="EmailIcon" aria-label="fontSize small"
												<path
													d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z">
												</path>
											</svg> Contact Provider</Button>
										</div>
									</div>
								</div>
								<div className="row mt-3">
									{ userFile &&
										userFile.map((item,index) => (
											<div className="col-md-6">
												<img src={item.url} style={{maxWidth:'100%'}}/>
											</div>
										))}
								</div>
							</div>
							<div className="col-md-12 col-lg-8">
								<div className={styles.rightCard1}>
									<div className="row">
										<div className="col-md-6">
											<div className="ng-tns-c138-0"><p  style={{fontSize: '1rem'}}>asdasddsf</p>
												<div className="text-right "><p className="text-end">-imran afzal3</p></div>
											</div>
										</div>
										<div className="col-md-6">
											<video controls={true} className="videoupload mt-0 "
														 style={{height: '200px',paddingTop: '30px',width:'100%'}}>
												<source className="ng-tns-c138-0" src="https://galeahealth-uploads.s3.amazonaws.com/2854/a5b13325-308e-4dac-9cab-2fa991c63eef.mp4" />
											</video>
										</div>
									</div>
								</div>

								<div className="text-end mt-5">
									<ButtonProfile>Next</ButtonProfile>
								</div>
							</div>
						</div>
					</Container>
				</div>
			</div>
		</>
	)
}

export default ViewProfile


