import React from "react"
import { Button } from "react-bootstrap"
import * as styles from "./styles.module.scss"

const ButtonProfile = ({ className, children, ...props }) => {
	return (
		<Button className={`${styles.button} ${className}`} {...props} >
			{children}
		</Button>
	)
}

export default ButtonProfile
