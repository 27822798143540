// extracted by mini-css-extract-plugin
export var account = "styles-module--account--5e0d6";
export var add = "styles-module--add--a5c70";
export var contactInfo = "styles-module--contactInfo--22551";
export var floatingLabel = "styles-module--floatingLabel--bcbc6";
export var formControl = "styles-module--formControl--0c8e7";
export var inputText = "styles-module--inputText--75262";
export var leftCard = "styles-module--leftCard--26eb6";
export var leftCardBtn = "styles-module--leftCardBtn--1a947";
export var leftCardH1 = "styles-module--leftCardH1--a3490";
export var leftCardH5 = "styles-module--leftCardH5--271b4";
export var leftCardImg = "styles-module--leftCardImg--e1bf6";
export var rightCard1 = "styles-module--rightCard1--159d2";
export var rightCard2 = "styles-module--rightCard2--a7ea6";
export var viewProfile = "styles-module--viewProfile--a1f76";