import * as React from "react"
import { Main } from "../components/layout"
import ViewProfile from "../components/pages/viewProfile"

// markup
const ViewProfilePage = () => {
	return (
		<>
			<Main>
				< ViewProfile/>
			</Main>
		</>
	)
}

export default ViewProfilePage
